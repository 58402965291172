define('ember-component-inbound-actions/inbound-actions', ['exports', 'ember', 'ember-component-inbound-actions/action-proxy'], function (exports, _ember, _actionProxy) {
  'use strict';

  exports.__esModule = true;
  exports.default = _ember.default.Mixin.create({
    _inbound_actions_setup: function () {
      _ember.default.run.schedule('afterRender', this, function () {
        var proxy = _actionProxy.default.create({ target: this });
        this.set('actionReceiver', proxy);
      });
    }.on('init')
  });
});