define('ember-rl-dropdown/mixins/rl-dropdown-component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    init: function init() {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this._super.apply(this, args);

      this.set('boundClickoutHandler', _ember['default'].run.bind(this, this.clickoutHandler));
      this.set('boundEscapeHandler', _ember['default'].run.bind(this, this.escapeHandler));
    },

    dropdownExpanded: false,

    dropdownToggleSelector: '.rl-dropdown-toggle',

    dropdownSelector: '.rl-dropdown',

    closingEventNamespace: 'rl-dropdown',

    closeOnEscape: true,

    actions: {
      toggleDropdown: function toggleDropdown() {
        this.toggleProperty('dropdownExpanded');
      },

      openDropdown: function openDropdown() {
        this.set('dropdownExpanded', true);
      },

      closeDropdown: function closeDropdown() {
        this.set('dropdownExpanded', false);
      }
    },

    manageClosingEvents: _ember['default'].on('didInsertElement', _ember['default'].observer('dropdownExpanded', function () {
      var namespace = this.get('closingEventNamespace');
      var clickEventName = 'click.' + namespace;
      var touchEventName = 'touchstart.' + namespace;
      var escapeEventName = 'keydown.' + namespace;
      var component = this;
      var $document = _ember['default'].$(document);

      if (this.get('dropdownExpanded')) {

        /* Add clickout handler with 1ms delay, to allow opening the dropdown
         * by clicking e.g. a checkbox and binding to dropdownExpanded, without
         * having the handler close the dropdown immediately. */
        _ember['default'].run.later(function () {
          $document.bind(clickEventName, { component: component }, component.boundClickoutHandler);
          $document.bind(touchEventName, { component: component }, component.boundClickoutHandler);
        }, 1);

        if (this.get('closeOnEscape')) {
          $document.bind(escapeEventName, { component: component }, component.boundEscapeHandler);
        }
      } else {
        $document.unbind(clickEventName, component.boundClickoutHandler);
        $document.unbind(touchEventName, component.boundClickoutHandler);
        $document.unbind(escapeEventName, component.boundEscapeHandler);
      }
    })),

    unbindClosingEvents: _ember['default'].on('willDestroyElement', function () {
      var namespace = this.get('closingEventNamespace');
      var $document = _ember['default'].$(document);

      $document.unbind('click.' + namespace, this.boundClickoutHandler);
      $document.unbind('touchstart.' + namespace, this.boundClickoutHandler);
      $document.unbind('keydown.' + namespace, this.boundEscapeHandler);
    }),

    clickoutHandler: function clickoutHandler(event) {
      var component = event.data.component;
      var $c = component.$();
      var $target = _ember['default'].$(event.target);

      /* There is an issue when the click triggered a dom change in the
       * dropdown that unloaded the target element. The ancestry of the target
       * can no longer be determined. We can check if html is still an ancestor
       * to determine if this has happened. The safe option then seems to be to
       * not close the dropdown, as occasionaly not closing the dropdown when it
       * should have closed, seems to be less bad for usability than occasionaly
       * closing the dropdown when it should not have closed.
       */
      if (component.get('dropdownExpanded') && $target.closest('html').length && !($target.closest($c.find(component.get('dropdownToggleSelector'))).length || $target.closest($c.find(component.get('dropdownSelector'))).length)) {
        component.set('dropdownExpanded', false);
      }
    },

    escapeHandler: function escapeHandler(event) {
      if (event.keyCode === 27) {
        event.data.component.set('dropdownExpanded', false);
      }
    }
  });
});