define('ember-notif-hub/services/ember-notification-center', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({
        store: _ember['default'].inject.service(),
        init: function init() {
            var _this = this;

            this._super();
            this.set('notifications.loading', true);
            this.get('store').findAll('ember-notification-local-notification').then(function (notifications) {
                notifications.forEach(function (notification) {
                    if (notification.get('hasDirtyAttributes')) {
                        return;
                    }
                    _this.get('notifications').pushObject(notification);
                });
                _this.set('notifications.loading', false);
            });
            this.get('store').findAll('ember-notification-local-error');
        },
        notifications: _ember['default'].A([]),
        clearAll: function clearAll() {
            var newArray = _ember['default'].A([]);
            this.get('notifications').forEach(function (notification) {
                if (notification.get('status') === 'Pending') {
                    newArray.push(notification);
                    return;
                }
                var promises = [];
                notification.get('errors').forEach(function (error) {
                    promises.push(error.destroyRecord());
                });
                notification.destroyRecord();
            });
            this.set('notifications', newArray);
        },
        inProgress: _ember['default'].computed('notifications.@each.status', function () {
            return this._countNotifications('Pending');
        }),
        failed: _ember['default'].computed('notifications.@each.status', function () {
            return this._countNotifications('Failed');
        }),
        succeeded: _ember['default'].computed('notifications.@each.status', function () {
            return this._countNotifications('Success');
        }),
        notificationsObserver: _ember['default'].computed('notifications.@each', 'notifications', function () {
            var _this2 = this;

            this.get('store').findAll('ember-notification-local-notification').then(function (notifications) {
                _this2.set('notifications', notifications);
            });
        }),
        pushNotification: function pushNotification(notification, promise) {
            var record = this.get('store').createRecord('ember-notification-local-notification', notification);
            if (!promise) {
                this._pushAlertNotification(record);
            } else if (promise.then) {
                record.promise = promise;
                this._pushTaskNotification(record);
            }
        },
        _countNotifications: function _countNotifications(status) {
            var count = 0;
            this.get('notifications').forEach(function (notif) {
                if (notif.get('status') === status) {
                    count += 1;
                }
            });
            return count;
        },
        _pushAlertNotification: function _pushAlertNotification(notification) {
            notification.save();
            this.notifications.unshiftObject(notification);
        },
        _pushTaskNotification: function _pushTaskNotification(notification) {
            var _this3 = this;

            notification.set('status', 'Pending');
            this.get('notifications').unshiftObject(notification);
            notification.promise.then(function () {
                _ember['default'].debug('setting succeeded');
                notification.set('status', 'Success');
                notification.save();
            })['catch'](function (err) {
                var promise = new _ember['default'].RSVP.Promise(function (resolve) {
                    resolve();
                });
                if (!err) {
                    return notification.set('status', 'Failed');
                }
                if (Array.isArray(err) || err.errors) {
                    (function () {
                        var errPromises = [];
                        var errs = Array.isArray(err) ? err : err.errors;
                        errs.forEach(function (error) {
                            var localError = _this3.get('store').createRecord('ember-notification-local-error', error);
                            notification.get('errors').pushObject(localError);
                            errPromises.push(localError.save());
                        });
                        promise = _ember['default'].RSVP.all(errPromises).then(function () {
                            return notification.save();
                        });
                    })();
                } else if (err.message) {
                    var localError = _this3.get('store').createRecord('ember-notification-local-error', {
                        code: 'Unknown Error',
                        title: err.message
                    });
                    notification.get('errors').pushObject(localError);
                    promise = localError.save();
                }
                promise.then(function () {
                    notification.set('status', 'Failed');
                    notification.save().then(function () {
                        _ember['default'].debug('saved local notification!');
                    })['catch'](function (err) {
                        _ember['default'].debug(err.message);
                    });
                });
            });
        }
    });
});