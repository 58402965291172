define('ember-stripe-elements/components/stripe-element', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    classNames: ['ember-stripe-element'],
    error: null,
    options: [],
    stripeElement: null,
    type: null, // Set in components that extend from `stripe-element`

    stripe: service(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var elements = get(this, 'stripe.elements')();

      // Fetch user options
      var options = get(this, 'options');

      // Fetch `type` set by child component
      var type = get(this, 'type');

      // `stripeElement` instead of `element` to distinguish from `this.element`
      var stripeElement = elements.create(type, options);

      // Mount the Stripe Element onto the mount point
      stripeElement.mount(this.element.querySelector('[role="mount-point"]'));

      // Make the element available to the component
      set(this, 'stripeElement', stripeElement);

      // Set the event listeners
      this.setEventListeners();
    },

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      get(this, 'stripeElement').update(get(this, 'options'));
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      get(this, 'stripeElement').unmount();
    },

    setEventListeners: function setEventListeners() {
      var _this = this;

      var stripeElement = get(this, 'stripeElement');
      stripeElement.on('blur', function () {
        return _this.sendAction('blur');
      });
      stripeElement.on('focus', function () {
        return _this.sendAction('focus');
      });
      stripeElement.on('change', function () {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        var error = args[0].error;

        set(_this, 'error', error);
        _this.sendAction.apply(_this, ['change'].concat(args));
      });
    }
  });
});