define("ember-collection/components/ember-collection/template", ["exports"], function (exports) {
  "use strict";

  exports.__esModule = true;
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 4
              },
              "end": {
                "line": 5,
                "column": 4
              }
            },
            "moduleName": "ember-collection/components/ember-collection/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [0]);
            var morphs = new Array(2);
            morphs[0] = dom.createUnsafeAttrMorph(element0, 'style');
            morphs[1] = dom.createMorphAt(element0, 0, 0);
            return morphs;
          },
          statements: [["attribute", "style", ["get", "cell.style", ["loc", [null, [4, 20], [4, 30]]]]], ["inline", "yield", [["get", "cell.item", ["loc", [null, [4, 42], [4, 51]]]], ["get", "cell.index", ["loc", [null, [4, 52], [4, 62]]]]], [], ["loc", [null, [4, 34], [4, 65]]]]],
          locals: ["cell"],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 7,
              "column": 0
            }
          },
          "moduleName": "ember-collection/components/ember-collection/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["block", "each", [["get", "_cells", ["loc", [null, [3, 13], [3, 19]]]]], [], 0, null, ["loc", [null, [3, 4], [5, 15]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 0
          }
        },
        "moduleName": "ember-collection/components/ember-collection/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "ember-native-scrollable", [], ["content-size", ["subexpr", "@mut", [["get", "_contentSize", ["loc", [null, [1, 40], [1, 52]]]]], [], []], "scroll-left", ["subexpr", "@mut", [["get", "_scrollLeft", ["loc", [null, [1, 65], [1, 76]]]]], [], []], "scroll-top", ["subexpr", "@mut", [["get", "_scrollTop", ["loc", [null, [1, 88], [1, 98]]]]], [], []], "scrollChange", ["subexpr", "action", ["scrollChange"], [], ["loc", [null, [1, 112], [1, 135]]]], "clientSizeChange", ["subexpr", "action", ["clientSizeChange"], [], ["loc", [null, [1, 153], [1, 180]]]]], 0, null, ["loc", [null, [1, 0], [7, 28]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});