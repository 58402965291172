define('ember-stripe-elements/services/stripe', ['exports', 'ember'], function (exports, _ember) {
  var getOwner = _ember['default'].getOwner;
  var Service = _ember['default'].Service;
  var setProperties = _ember['default'].setProperties;
  exports['default'] = Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var config = getOwner(this).resolveRegistration('config:environment');

      if (!config.stripe && !config.stripe.publishableKey) {
        throw new _ember['default'].Error('StripeService: Missing Stripe key, please set `ENV.stripe.publishableKey` in config.environment.js');
      }

      var _ref = new Stripe(config.stripe.publishableKey);

      var elements = _ref.elements;
      var createToken = _ref.createToken;

      setProperties(this, { elements: elements, createToken: createToken });
    }
  });
});
/* global Stripe */