define('ember-notif-hub/components/ember-notification-center', ['exports', 'ember', 'ember-notif-hub/templates/components/ember-notification-center'], function (exports, _ember, _emberNotifHubTemplatesComponentsEmberNotificationCenter) {
    exports['default'] = _ember['default'].Component.extend({
        layout: _emberNotifHubTemplatesComponentsEmberNotificationCenter['default'],
        notifications: _ember['default'].inject.service('emberNotificationCenter'),
        isPulloutVisible: false,
        showLastNotification: false,
        lastToggle: null,
        didRender: function didRender() {
            this.set('rendered', true);
        },
        _scheduleToggle: function _scheduleToggle() {
            var _this = this;

            var lastToggle = _ember['default'].run.later(function () {
                _this.send('togglePullout');
                _this.set('lastToggle', null);
            }, 3000);
            this.set('lastToggle', lastToggle);
        },
        _cancelToggle: function _cancelToggle() {
            if (this.get('lastToggle')) {
                _ember['default'].run.cancel(this.get('lastToggle'));
                this.set('lastToggle', null);
            }
        },
        lastNotification: _ember['default'].computed.alias('notifications.notifications.firstObject'),
        lastNotificationObserver: _ember['default'].observer('lastNotification', function (target) {
            if (target.get('firstTime')) {
                target.set('firstTime', false);
                return;
            }
            if (target.get('notifications.notifications.loading')) {
                return;
            }
            if (target.get('lastNotification.promise')) {
                if (!target.get('isPulloutVisible')) {
                    target.send('togglePullout');
                }
                target._cancelToggle();
                target.get('lastNotification.promise').then(function () {
                    if (!target.get('isPulloutVisible')) {
                        // user collapsed pullout before finishing
                        return;
                    }
                    target._scheduleToggle();
                });
            } else {
                if (target.get('notifications.inProgress')) {
                    // theres still an async promise going on
                    return;
                }
                // synchronous notification
                if (target.get('lastToggle')) {
                    target._cancelToggle();
                } else {
                    target._scheduleToggle();
                }
            }
        }),
        actions: {
            retryFailedAction: function retryFailedAction(notification) {
                notification.get('retryTarget').send('retryAction', notification.get('retryArgs'));
            },
            togglePullout: function togglePullout() {
                if (this.get('lastToggle')) {
                    _ember['default'].run.cancel(this.get('lastToggle'));
                }
                // This hack is required since Chrome isn't smart enough
                // to trigger our animations right away. We're just
                // redrawing those elements.
                this.set('showLastNotification', false);
                this.toggleProperty('isPulloutVisible');
            }
        }
    });
});